// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-notation-js": () => import("../src/templates/notation.js" /* webpackChunkName: "component---src-templates-notation-js" */),
  "component---src-templates-world-js": () => import("../src/templates/world.js" /* webpackChunkName: "component---src-templates-world-js" */),
  "component---src-templates-worlds-js": () => import("../src/templates/worlds.js" /* webpackChunkName: "component---src-templates-worlds-js" */),
  "component---src-templates-list-js": () => import("../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-about-js": () => import("../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-appendix-js": () => import("../src/templates/appendix.js" /* webpackChunkName: "component---src-templates-appendix-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-video-js": () => import("../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-photo-js": () => import("../src/templates/photo.js" /* webpackChunkName: "component---src-templates-photo-js" */),
  "component---src-templates-donation-js": () => import("../src/templates/donation.js" /* webpackChunkName: "component---src-templates-donation-js" */),
  "component---src-templates-doc-js": () => import("../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

